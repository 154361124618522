import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from '../App';
import {
  AddChargeStationLocationPage,
  AddTestRidePage,
  ChargeStationLocationPage,
  ChargeStationLocationsPage,
  InactiveVehiclesPage,
  SettingApplicationPage,
  SettingNotificationPage,
  SettingsPage,
  StartPage,
  TestRidesPage,
  UpdateBikePage,
  UpdateBookingPage,
  UpdateChargeStationLocationPage,
  UpdateTestRide,
  VehicleLogsPage,
  BikeModelsPage,
  BikeModelPage,
  AddBikeModelPage,
  UpdateBikeModelPage,
  BatteriesPage,
  AddBatteryPage,
  UpdateBatteryPage,
  BatteryLogsPage,
  ChargeStationsPage,
  ChargeStationPage,
  AddChargeStationPage,
  UpdateChargeStationPage,
  ServiceCentersPage,
  ServiceCenterPage,
  AddServiceCenterPage,
  UpdateServiceCenterPage,
  CustomersPage,
  CustomerPage,
  AddCustomerPage,
  BikeDetailPage,
  BikeOwnershipPage,
  AddBikeOwnershipPage,
  FaqsPage,
  AddFaqPage,
  UpdateFaqPage,
  ServiceCenterAppointmentsPage,
  AddServicingAppointmentPage,
  UpdateServiceCenterAppointment,
  AddChargeStationDockPage,
  UpdateChargeStationDockPage,
  ServicingAppointmentsPage,
  ServicingAppointmentPage,
  BikesPage,
  AddBikePage,
  BikeCrashLogsPage,
  BikeBatterySwapsHistory,
  BikeBatterySwapLogs,
  BookingsPage,
  BookingPage,
  ProfilePage,
  StaffsPage,
  StaffAddPage,
  UpdateStaffPage,
  WalletTransactionsPage,
  WalletTransactionPage,
  BikeOwnershipLogsPage,
  AddPaymentPage,
  ChargeStationStatsPage
} from '../pages';
import RootComponent from '../RootComponent';
import NotFoundPage from '../components/commons/NotFound';
import ProtectedRoute from './ProtectedRoutes';
import { ROLES } from '../constants';

function AppRoutes(): JSX.Element {
  return (
    <BrowserRouter>
      <App>
        <RootComponent>
          <Routes>
            <Route path="/" element={<StartPage />} />

            <Route
              path="/bike-models"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.level1, ROLES.productionMaintainer]}
                >
                  <BikeModelsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bike-models/add"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.level0, ROLES.productionMaintainer]}
                >
                  <AddBikeModelPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bike-models/:id/edit"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.level0, ROLES.productionMaintainer]}
                >
                  <UpdateBikeModelPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bike-models/:id"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.level1, ROLES.productionMaintainer]}
                >
                  <BikeModelPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/batteries"
              element={
                <ProtectedRoute
                  authorizedRoles={[
                    ROLES.admin,
                    ROLES.serviceCenterStaff,
                    ROLES.level1,
                    ROLES.productionMaintainer
                  ]}
                >
                  <BatteriesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/batteries/add"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.productionMaintainer]}>
                  <AddBatteryPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/batteries/:id/edit"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.productionMaintainer]}>
                  <UpdateBatteryPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/batteries/:batteryId/logs"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.serviceCenterStaff, ROLES.level1]}
                >
                  <BatteryLogsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/charge-station-locations"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.level1]}>
                  <ChargeStationLocationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/charge-station-locations/add"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.level1]}>
                  <AddChargeStationLocationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/charge-station-locations/:id"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.level1]}>
                  <ChargeStationLocationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/charge-station-locations/:id/edit"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.level1]}>
                  <UpdateChargeStationLocationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/charge-stations"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.level1]}>
                  <ChargeStationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/charge-stations/add"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.manager]}>
                  <AddChargeStationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/charge-stations/:id/edit"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.manager]}>
                  <UpdateChargeStationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/charge-stations/:id"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.level1]}>
                  <ChargeStationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/charge-stations/:id/stats"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.level1]}>
                  <ChargeStationStatsPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/charge-stations/:id/docks/add"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.manager]}>
                  <AddChargeStationDockPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/charge-stations/:id/docks/:dockId/edit"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.manager]}>
                  <UpdateChargeStationDockPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/service-centers"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.serviceCenterStaff, ROLES.level1]}
                >
                  <ServiceCentersPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/service-centers/add"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.manager]}>
                  <AddServiceCenterPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/service-centers/:id/appointments"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.serviceCenterStaff, ROLES.level1]}
                >
                  <ServiceCenterAppointmentsPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/service-centers/:id/appointments/add"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.serviceCenterStaff, ROLES.level1]}
                >
                  <AddServicingAppointmentPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/service-centers/:id/appointments/:appointmentId/edit"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.serviceCenterStaff, ROLES.level1]}
                >
                  <UpdateServiceCenterAppointment />
                </ProtectedRoute>
              }
            />

            <Route
              path="/service-centers/:id/edit"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.manager]}>
                  <UpdateServiceCenterPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/service-centers/:id"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.serviceCenterStaff, ROLES.level1]}
                >
                  <ServiceCenterPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/customers"
              element={
                <ProtectedRoute
                  authorizedRoles={[
                    ROLES.admin,
                    ROLES.finance,
                    ROLES.level1,
                    ROLES.serviceCenterStaff
                  ]}
                >
                  <CustomersPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/customers/add"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin]}>
                  <AddCustomerPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/customers/:id"
              element={
                <ProtectedRoute
                  authorizedRoles={[
                    ROLES.admin,
                    ROLES.finance,
                    ROLES.level1,
                    ROLES.serviceCenterStaff
                  ]}
                >
                  <CustomerPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/bikes"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.level1, ROLES.productionMaintainer]}
                >
                  <BikesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bikes/add"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.productionMaintainer]}>
                  <AddBikePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bikes/:bikeId/edit"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.productionMaintainer]}>
                  <UpdateBikePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bikes/:bikeId"
              element={
                <ProtectedRoute
                  authorizedRoles={[
                    ROLES.admin,
                    ROLES.serviceCenterStaff,
                    ROLES.level1,
                    ROLES.productionMaintainer
                  ]}
                >
                  <BikeDetailPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bikes/:bikeId/ownership"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.serviceCenterStaff, ROLES.level1]}
                >
                  <BikeOwnershipLogsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bikes/:bikeId/battery-swaps"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.serviceCenterStaff, ROLES.level1]}
                >
                  <BikeBatterySwapsHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bikes/:bikeId/battery-swaps/:batterySwapHistoryId/logs"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.serviceCenterStaff, ROLES.level1]}
                >
                  <BikeBatterySwapLogs />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vehicle-logs"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.level0]}>
                  <VehicleLogsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bike-ownership"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin]}>
                  <BikeOwnershipPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bike-ownership/add"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin]}>
                  <AddBikeOwnershipPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/faqs"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.ecStaff, ROLES.level1]}>
                  <FaqsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/faqs/add"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.ecStaff, ROLES.level1]}>
                  <AddFaqPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/faqs/:id/edit"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.ecStaff, ROLES.level1]}>
                  <UpdateFaqPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/servicing-appointments"
              element={
                <ProtectedRoute
                  authorizedRoles={[
                    ROLES.admin,
                    ROLES.serviceCenterStaff,
                    ROLES.level1,
                    ROLES.finance
                  ]}
                >
                  <ServicingAppointmentsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/servicing-appointments/:appointmentId"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.serviceCenterStaff, ROLES.level1]}
                >
                  <ServicingAppointmentPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/crash-logs"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.level1]}>
                  <BikeCrashLogsPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/bookings"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.ecStaff, ROLES.level1]}>
                  <BookingsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bookings/:id"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.ecStaff, ROLES.level1]}>
                  <BookingPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bookings/:id/edit"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.ecStaff, ROLES.level1]}>
                  <UpdateBookingPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/test-rides"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.ecStaff]}>
                  <TestRidesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/test-rides/:id/edit"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.ecStaff]}>
                  <UpdateTestRide />
                </ProtectedRoute>
              }
            />
            <Route
              path="/test-rides/add"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.ecStaff]}>
                  <AddTestRidePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute authorizedRoles={Object.values(ROLES)}>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/staffs"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.level1]}>
                  <StaffsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/staffs/add"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin]}>
                  <StaffAddPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/staffs/:staffId/edit"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin]}>
                  <UpdateStaffPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/payments"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.finance, ROLES.level1]}>
                  <WalletTransactionsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payments/:transactionId"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.finance, ROLES.level1]}>
                  <WalletTransactionPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payments/add"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.finance]}>
                  <AddPaymentPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.level0]}>
                  <SettingsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/general"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.level0]}>
                  <SettingApplicationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings/notifications"
              element={
                <ProtectedRoute authorizedRoles={[ROLES.admin, ROLES.level0]}>
                  <SettingNotificationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/inactive-vehicles"
              element={
                <ProtectedRoute
                  authorizedRoles={[ROLES.admin, ROLES.level1, ROLES.serviceCenterStaff]}
                >
                  <InactiveVehiclesPage />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </RootComponent>
      </App>
    </BrowserRouter>
  );
}

export default AppRoutes;
