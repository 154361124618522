import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ROLES } from '../../constants';
import AuthorizeView from '../../hoc/authorizeView';
import { ChargeStation } from '../../interfaces';
import ChargingDocks from './ChargingDocks';

const ChargeStationDetail = ({ chargeStation }: { chargeStation: ChargeStation }): JSX.Element => {
  const { code } = chargeStation;
  return (
    <div className="body-card mb-3">
      <Row>
        <Col>
          <div className="head-panel d-flex">
            <div className="head-left">
              <Link className="link" to={`/charge-stations/${chargeStation._id}`}>
                <h4>#{code}</h4>
              </Link>
            </div>
            <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.manager]}>
              <div className="head-right">
                <Link
                  to={`/charge-stations/${chargeStation._id}/docks/add`}
                  className="btn btn-primary"
                >
                  Add charging dock
                </Link>
              </div>
            </AuthorizeView>
          </div>
        </Col>
      </Row>
      <ChargingDocks chargeStation={chargeStation} />
    </div>
  );
};

export default ChargeStationDetail;
