import React from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Row, Table } from 'react-bootstrap';
import { usePromiseFetch } from '../../hooks';
import { TableLoader } from '../../components/commons/Loader';
import { WalletTransaction } from '../../interfaces';
import Paginate from '../../components/paginate';

function ChargeStationTransactions(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams<{ id: string }>();

  const [fetching, response] = usePromiseFetch(
    `/wallets/transactions?chargeStation=${id}`,
    searchParams
  );

  if (fetching) return <TableLoader />;

  const {
    transactions,
    size,
    total
  }: { transactions: WalletTransaction[]; size: number; total: number } = response
    ? response
    : { transactions: [], size: 40, total: 0 };

  const totalPage = Math.ceil(total / size);

  return (
    <div className="mt-4">
      <Row>
        <div className="head-panel d-flex">
          <div className="head-left">
            <h1 className="page-title">Transactions</h1>
            <span className="text-muted">{total} entries found</span>
          </div>
        </div>
      </Row>
      <Table responsive hover>
        <thead className="table-light">
          <tr>
            <th>Transaction id</th>
            <th>Amount</th>
            <th>Energy consumed</th>
            <th>Dock id</th>
            <th>Timestamp</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {transactions.map(({ _id, amount, chargingPayment, createdAt }) => (
            <tr key={_id}>
              <td>
                <small>{_id}</small>
              </td>
              <td>
                <strong className="text-success">
                  <small>NPR.</small>
                  {amount}
                </strong>
              </td>
              <td>
                {typeof chargingPayment?.eUnit !== 'undefined' ? (
                  `${chargingPayment?.eUnit} kWh`
                ) : (
                  <div className="badge rounded-pill bg-warning">Pending</div>
                )}
              </td>
              <td>{chargingPayment?.chargingDock}</td>
              <td>{new Date(createdAt).toLocaleString()}</td>

              <td>
                <Link className="btn btn-sm" to={`/payments/${_id}`}>
                  <i className="fas fa-eye"></i>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        <Paginate
          totalPage={totalPage}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </Row>
    </div>
  );
}

export default ChargeStationTransactions;
