/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ComponentType } from 'react';
import { Link, NavLink } from 'react-router-dom';
import TopNav from '../../components/topNav';
import { ROLES } from '../../constants';
import AuthorizeView from '../authorizeView';
import CollapseNavLink from '../CollapseNavLink';
import ServicingAppointmentLink from './ServicingAppointmentLink';
import './styles.scss';

export const withDashboard = (ChildComponent: ComponentType<any>) => (props: any) => (
  <div id="mjlApp">
    <nav className="nav-right">
      <div className="nav-brand d-flex">
        <div className="img-wrap">
          <img src="/favicon.ico" alt="logo" />
        </div>
      </div>
      <ul className="nav-links neutralize">
        <li>
          <NavLink to="/">
            <div className="s-icon">
              <i className="fas fa-home"></i>
            </div>{' '}
            Home
          </NavLink>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.finance, ROLES.level1]}>
            <NavLink to="/payments">
              <div className="s-icon">
                <i className="fas fa-wallet"></i>
              </div>{' '}
              Payments
            </NavLink>
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level1, ROLES.productionMaintainer]}>
            <NavLink to="/bike-models">
              <div className="s-icon">
                <i className="fas fa-layer-group"></i>
              </div>{' '}
              Bike models
            </NavLink>
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level1, ROLES.productionMaintainer]}>
            <NavLink to="/batteries">
              <div className="s-icon">
                <i className="fas fa-battery-full"></i>
              </div>{' '}
              Batteries
            </NavLink>
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level1, ROLES.productionMaintainer]}>
            <NavLink to="/bikes">
              <div className="s-icon">
                <i className="fas fa-biking"></i>
              </div>{' '}
              Bikes
            </NavLink>
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level1, ROLES.serviceCenterStaff]}>
            <NavLink to="/inactive-vehicles">
              <div className="s-icon">
                <i className="far fa-frown"></i>
              </div>{' '}
              Inactive vehicles
            </NavLink>
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level0]}>
            <NavLink to="/vehicle-logs?sort=-createdAt">
              <div className="s-icon">
                <i className="fas fa-file-alt"></i>
              </div>{' '}
              Vehicle logs
            </NavLink>
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level1]}>
            <CollapseNavLink
              id="chargeStationCollapse"
              linkText="Charge stations"
              icon={<i className="fas fa-bolt" />}
            >
              <ul>
                <li>
                  <NavLink to="/charge-station-locations?sort=-createdAt&isEnabled=true">
                    <div className="s-icon">
                      <i className="fas fa-map-pin" />
                    </div>{' '}
                    View station locations
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/charge-stations?sort=-createdAt&isEnabled=true">
                    <div className="s-icon">
                      <i className="fas fa-charging-station"></i>
                    </div>{' '}
                    View stations
                  </NavLink>
                </li>
              </ul>
            </CollapseNavLink>

            {/* <div className="collapse multi-collapse" id="multiCollapseExample1">
              <NavLink to="/charge-stations?sort=-createdAt&isEnabled=true">
                <div className="s-icon">
                  <i className="fas fa-charging-station"></i>
                </div>{' '}
                Charge stations
              </NavLink>
            </div> */}
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level1, ROLES.serviceCenterStaff]}>
            <NavLink to="/service-centers">
              <div className="s-icon">
                <i className="fas fa-wrench"></i>
              </div>{' '}
              Service centers
            </NavLink>
          </AuthorizeView>
        </li>

        <li>
          <AuthorizeView
            authorizedRoles={[ROLES.admin, ROLES.finance, ROLES.level1, ROLES.serviceCenterStaff]}
          >
            <NavLink to="/customers">
              <div className="s-icon">
                <i className="fas fa-users"></i>
              </div>{' '}
              Customers
            </NavLink>
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView
            authorizedRoles={[ROLES.admin, ROLES.level1, ROLES.serviceCenterStaff, ROLES.finance]}
          >
            <ServicingAppointmentLink />
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin]}>
            <NavLink to="/bike-ownership">
              <div className="s-icon">
                <i className="fas fa-link"></i>
              </div>{' '}
              Ownership
            </NavLink>
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.ecStaff, ROLES.level1]}>
            <NavLink to="/bookings">
              <div className="s-icon">
                <i className="fab fa-buromobelexperte"></i>
              </div>{' '}
              Bookings
            </NavLink>
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.ecStaff]}>
            <NavLink to="/test-rides">
              <div className="s-icon">
                <i className="fab fa-wpforms"></i>
              </div>{' '}
              Test rides
            </NavLink>
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.ecStaff, ROLES.level1]}>
            <NavLink to="/faqs">
              <div className="s-icon">
                <i className="far fa-question-circle"></i>
              </div>{' '}
              FAQs
            </NavLink>
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level1]}>
            <NavLink to="/crash-logs">
              <div className="s-icon">
                <i className="fas fa-car-crash"></i>
              </div>{' '}
              Crash logs
            </NavLink>
          </AuthorizeView>
        </li>
        <li>
          <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level1]}>
            <NavLink to="/staffs">
              <div className="s-icon">
                <i className="fas fa-id-badge"></i>
              </div>{' '}
              Staffs
            </NavLink>
          </AuthorizeView>
        </li>
      </ul>

      <div
        style={{
          position: 'absolute',
          width: '100%',
          background: '#1e2432',
          bottom: 0,
          left: 0,
          color: '#007bff',
          fontSize: '.8rem',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0.5rem 1rem',
          alignItems: 'center'
          // textAlign: 'center'
        }}
      >
        <div style={{ padding: '0.375rem 0.75rem' }}>&copy; Yatri Motorcycles</div>
        <AuthorizeView authorizedRoles={[ROLES.admin, ROLES.level0]}>
          <Link to="/settings" className="btn btn-chromeless text-white">
            <i className="fas fa-cog"></i>
          </Link>
        </AuthorizeView>
      </div>
    </nav>
    <div className="_main_panel">
      <TopNav />
      <div className="_bod_wrap">
        <div className="_bod_content">
          <ChildComponent {...props} />
        </div>
      </div>
    </div>
  </div>
);
